<template>
  <div>
    <!-- Modal -->
    <div
      class="modal fade"
      id="modal-form-validate-viaje"
      tabindex="-1"
      role="dialog"
      aria-hidden="true"
      data-backdrop="static"
      data-keyboard="false"
    >
      <div class="modal-dialog modal-md">
        <div class="modal-content">
          <div class="modal-header bg-frontera-top-left pt-2 pb-2">
            <h4 class="modal-title text-white">Validar Programación</h4>
            <button
              type="button"
              class="close text-white"
              data-dismiss="modal"
              aria-label="Close"
              id="close-modal"
              ref="closeModal"
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div class="modal-body">
            <div class="row">
              <div class="col-md-6">
                <div class="form-group">
                  <label for="vehiculo">Vehiculo</label>
                  <v-select
                    :class="[
                      $v.form.vehiculo_id.$invalid ? 'is-invalid' : 'is-valid',
                      $store.getters.getDarkMode ? 'dark-vselect' : '',
                    ]"
                    v-model="vehiculo"
                    placeholder="Placa"
                    label="placa"
                    class="form-control form-control-sm p-0"
                    :options="listasForms.vehiculos"
                    :filterable="false"
                    @search="buscarVehiculos"
                    @input="selectVehiculo()"
                  ></v-select>
                </div>
              </div>
              <div class="col-md-6">
                <div class="form-group">
                  <label for="remolque">Remolque</label>
                  <v-select
                    :class="[
                      $v.form.remolque_id.$invalid ? 'is-invalid' : 'is-valid',
                      $store.getters.getDarkMode ? 'dark-vselect' : '',
                    ]"
                    v-model="remolque"
                    placeholder="Placa"
                    label="placa"
                    class="form-control form-control-sm p-0"
                    :options="listasForms.remolques"
                    :filterable="false"
                    @search="buscarRemolques"
                    @input="selectRemolque()"
                  ></v-select>
                </div>
              </div>
            </div>
          </div>
          <div class="modal-footer">
            <div class="row">
              <div class="col-md-3">
                <button
                  type="button"
                  class="btn btn-success"
                  v-show="
                    !$v.form.vehiculo_id.$invalid ||
                      !$v.form.remolque_id.$invalid
                  "
                  @click="validarProgrmacion()"
                >
                  <i class="fas fa-check-double col-md-12"></i>
                  <strong> Validar</strong>
                </button>
              </div>
              <div class="col-md-4">
                <button
                  type="button"
                  class="btn btn-primary"
                  @click="crearViajeManual()"
                  v-if="
                    $store.getters.can(
                      'hidrocarburos.ventanasOperaciones.crearViaje'
                    ) && $parent.filtros.tipo_control == 1
                  "
                >
                  <i class="fas fa-spell-check col-md-12"></i>
                  <strong> Viaje manual</strong>
                </button>
              </div>
              <div class="col-md-5">
                <button
                  type="button"
                  class="btn btn-dark"
                  @click="crearViajeManualRegalias()"
                  v-if="
                    $store.getters.can(
                      'hidrocarburos.ventanasOperaciones.crearViajeRegalias'
                    ) && $parent.filtros.tipo_control == 3
                  "
                >
                  <i class="fas fa-route col-md-12"></i>
                  <strong> Viaje Manual Regalias / Terceros</strong>
                </button>
              </div>
            </div>
          </div>
        </div>
        <!-- /.modal-content -->
      </div>
      <!-- /.modal-dialog -->
    </div>
  </div>
</template>
<script>
import axios from "axios";
import { required } from "vuelidate/lib/validators";
import vSelect from "vue-select";
export default {
  name: "VentanaOperacionesValidateViaje",
  components: {
    vSelect,
  },
  data() {
    return {
      form: {
        vehiculo_id: null,
        remolque_id: null,
      },
      vehiculo: [],
      remolque: [],
      programacion: {},
      listasForms: {
        vehiculos: [],
        remolques: [],
      },
      tipo_control: null,
    };
  },
  validations: {
    form: {
      vehiculo_id: {
        required,
      },
      remolque_id: {
        required,
      },
    },
  },
  methods: {
    crearViajeManual() {
      this.$parent.filtros.sitio_type == 1;
      this.$refs.closeModal.click();
      return this.$router.push({
        name: "/Hidrocarburos/ViajeForm",
        params: {
          accion: "Crear",
          acto: 2,
          tipo_control: this.$parent.filtros.tipo_control,
          sitio_id: this.$parent.slct_sitio.id,
          sitio: this.$parent.slct_sitio.nombre,
          sitio_type: this.$parent.filtros.sitio_type,
        },
      });
    },

    crearViajeManualRegalias() {
      this.$parent.filtros.sitio_type == 1;
      this.$refs.closeModal.click();
      return this.$router.push({
        name: "/Hidrocarburos/ViajeFormRegalias",
        params: {
          accion: "Crear",
          acto: 2,
          tipo_control: this.$parent.filtros.tipo_control,
          sitio_id: this.$parent.slct_sitio.id,
          sitio: this.$parent.slct_sitio.nombre,
          sitio_type: this.$parent.filtros.sitio_type,
        },
      });
    },

    validarProgrmacion() {
      let vehiculo_id = this.form.vehiculo_id;
      let remolque_id = this.form.remolque_id;
      let sitio_id = this.$parent.slct_sitio.id;
      let sitio_type = this.$parent.filtros.sitio_type;

      let sitio_type_txt =
        this.$parent.filtros.sitio_type == 1
          ? `App\\Sitio`
          : `App\\PuntoGestion`;

      let estado_viaje = this.$parent.filtros.sitio_type == 1 ? 7 : 1;

      let form = {
        vehiculo_id,
        remolque_id,
        sitio_id,
        sitio_type,
      };
      axios
        .get("/api/hidrocarburos/ventanasEnturnamientoOperacion/validarViaje", {
          params: form,
        })
        .then((response) => {
          this.programacion = response.data;
          if (this.programacion.mensaje) {
            this.$swal({
              icon: "error",
              title: this.programacion.mensaje,
              toast: true,
              position: "bottom-end",
              showConfirmButton: false,
              timer: 8000,
              timerProgressBar: true,
            });
          } else {
            if (this.programacion.remolque != null) {
              this.$swal({
                title:
                  "Se encontro programación para el dia " +
                  this.programacion.det_solicitud_diaria.solicitud_diaria.fecha,
                html: `Conductor: ${this.programacion.conductor.nombres} ${this.programacion.conductor.apellidos} <br>
                Vehiculo: ${this.programacion.vehiculo.placa} <br>
                Remolque: ${this.programacion.remolque.placa} <br>
                Transportadora: ${this.programacion.det_solicitud_diaria.empresa.razon_social} <br>
                 
                <b>Desea crear un viaje para esta programación?<b>`,
                icon: "info",
                showCancelButton: true,
                confirmButtonText: "Si, Crear!",
                cancelButtonText: "Cancelar",
              }).then((result) => {
                if (result.value) {
                  this.$swal({
                    title: "Esta seguro de crear el viaje ?",
                    icon: "warning",
                    showCancelButton: true,
                    confirmButtonText: "Si, Crear!",
                    cancelButtonText: "Cancelar",
                  }).then((result) => {
                    if (result.value) {
                      let hoy = new Date();
                      let form_viaje = {
                        vehiculo_id: this.programacion.vehiculo.id,
                        conductor_id: this.programacion.conductor.id,
                        remolque_id: this.programacion.remolque.id,
                        transportadora_id: this.programacion
                          .det_solicitud_diaria.empresa.id,
                        tipo_producto_id: this.programacion.det_solicitud_diaria
                          .tipo_producto_id,
                        tipo_vehiculo_id: this.programacion.det_solicitud_diaria
                          .tipo_vehiculo_id,
                        sitio_cargue_id: this.programacion.det_solicitud_diaria
                          .solicitud_diaria.sitio_id,
                        fecha_inicial: `${hoy.getFullYear()}-${hoy.getMonth() +
                          1}-${hoy.getDay()}`,
                        hora_inicial: `${hoy.getHours()}:${hoy.getMinutes()}:${hoy.getSeconds()}`,
                        det_postulacion_id: this.programacion.id,
                        celular_conductor: this.programacion.conductor
                          .celular_principal,
                        tipo_ruta: this.programacion.det_solicitud_diaria
                          .tipo_ruta,
                        orden_de_cargue: this.programacion.orden_cargue,
                        link_orden_cargue: this.programacion.link_orden_cargue,
                        clase_viaje: 1,
                        sitio_type: sitio_type_txt,
                        sitio_id: sitio_id,
                        tipo_operacion: 1,
                        estado: estado_viaje,
                        fecha_cita: this.programacion.det_solicitud_diaria
                          .solicitud_diaria.fecha,
                        hora_cita: this.programacion.det_solicitud_diaria.cita,
                        cita_cargue:
                          this.programacion.det_solicitud_diaria
                            .solicitud_diaria.fecha +
                          " " +
                          this.programacion.det_solicitud_diaria.cita,
                        api: this.programacion.api,
                        volumen_calculado: this.programacion.volumen_calculado
                      };
                      this.crearViaje(form_viaje);
                    }
                  });

                  this.$refs.closeModal.click();
                }
              });
            } else {
              this.$swal({
                title:
                  "Se encontro programación para el dia " +
                  this.programacion.det_solicitud_diaria.solicitud_diaria.fecha,
                html: `Conductor: ${this.programacion.conductor.nombres} ${this.programacion.conductor.apellidos} <br>
                Vehiculo: ${this.programacion.vehiculo.placa} <br>
                Remolque: No presenta remolque <br>
                Transportadora: ${this.programacion.det_solicitud_diaria.empresa.razon_social} <br>
                 
                <b>Desea crear un viaje para esta programación?<b>`,
                icon: "info",
                showCancelButton: true,
                confirmButtonText: "Si, Crear!",
                cancelButtonText: "Cancelar",
              }).then((result) => {
                if (result.value) {
                  this.$swal({
                    title: "Esta seguro de crear el viaje ?",
                    icon: "warning",
                    showCancelButton: true,
                    confirmButtonText: "Si, Crear!",
                    cancelButtonText: "Cancelar",
                  }).then((result) => {
                    if (result.value) {
                      let hoy = new Date();
                      let form_viaje = {
                        vehiculo_id: this.programacion.vehiculo.id,
                        conductor_id: this.programacion.conductor.id,
                        remolque_id: null,
                        transportadora_id: this.programacion
                          .det_solicitud_diaria.empresa.id,
                        tipo_producto_id: this.programacion.det_solicitud_diaria
                          .tipo_producto_id,
                        tipo_vehiculo_id: this.programacion.det_solicitud_diaria
                          .tipo_vehiculo_id,
                        sitio_cargue_id: this.programacion.det_solicitud_diaria
                          .solicitud_diaria.sitio_id,
                        fecha_inicial: `${hoy.getFullYear()}-${hoy.getMonth() +
                          1}-${hoy.getDay()}`,
                        hora_inicial: `${hoy.getHours()}:${hoy.getMinutes()}:${hoy.getSeconds()}`,
                        det_postulacion_id: this.programacion.id,
                        celular_conductor: this.programacion.conductor
                          .celular_principal,
                        tipo_ruta: this.programacion.det_solicitud_diaria
                          .tipo_ruta,
                        orden_de_cargue: this.programacion.orden_cargue,
                        link_orden_cargue: this.programacion.link_orden_cargue,
                        clase_viaje: 1,
                        sitio_type: sitio_type_txt,
                        sitio_id: sitio_id,
                        tipo_operacion: 1,
                        estado: estado_viaje,
                        cita_cargue:
                          this.programacion.det_solicitud_diaria
                            .solicitud_diaria.fecha +
                          " " +
                          this.programacion.det_solicitud_diaria.cita,
                      };
                      this.crearViaje(form_viaje);
                    }
                  });

                  this.$refs.closeModal.click();
                }
              });
            }
          }
        })
        .catch((error) => {
          this.$swal({
            icon: "error",
            title: "No cuenta con un viaje ni una programación activa" + error,
            toast: true,
            position: "top-end",
            showConfirmButton: false,
            timer: 3000,
            timerProgressBar: true,
          });
        });
    },

    crearViaje(form) {
      axios({
        method: "POST",
        url: "/api/hidrocarburos/viajes",
        data: form,
      })
        .then(() => {
          this.$swal({
            icon: "success",
            title: "El viaje se guardo exitosamente...",
            toast: true,
            position: "top-end",
            showConfirmButton: false,
            timer: 3000,
            timerProgressBar: true,
          });
        })
        .catch((e) => {
          this.$swal({
            icon: "error",
            title: "Ocurrio un error, vuelva a intentarlo..." + e,
            toast: true,
            position: "top-end",
            showConfirmButton: false,
            timer: 3000,
            timerProgressBar: true,
          });
        });
    },

    buscarVehiculos(search) {
      let me = this;
      me.listasForms.vehiculos = [];
      if (search != "") {
        var url = "api/admin/vehiculos/lista?placa=" + search;
        axios
          .get(url, {
            params: {
              empresa_id: me.empresa_id,
            },
          })
          .then(function(response) {
            me.listasForms.vehiculos = response.data;
          })
          .catch(function(e) {
            me.$swal({
              icon: "error",
              title: "Ocurrio un error" + e,
              toast: true,
              position: "top-end",
              showConfirmButton: false,
              timer: 3000,
              timerProgressBar: true,
            });
          });
      }
    },

    selectVehiculo() {
      this.form.vehiculo_id = "";
      if (this.vehiculo) {
        this.form.vehiculo_id = this.vehiculo.id;
      }
    },

    buscarRemolques(search) {
      let me = this;
      me.listasForms.remolques = [];
      if (search != "") {
        var url = "api/admin/remolques/lista?placa=" + search;
        axios
          .get(url, {
            params: {
              // empresa_id: me.empresa_id,
            },
          })
          .then(function(response) {
            me.listasForms.remolques = response.data;
          })
          .catch(function(e) {
            this.$swal({
              icon: "error",
              title: "Ocurrio un error" + e,
              toast: true,
              position: "top-end",
              showConfirmButton: false,
              timer: 3000,
              timerProgressBar: true,
            });
          });
      }
    },

    selectRemolque() {
      this.form.remolque_id = "";
      if (this.remolque) {
        this.form.remolque_id = this.remolque.id;
      }
    },
  },
};
</script>
